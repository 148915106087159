import React from "react";
import { Link } from "gatsby";
import "./blog-title.scss";

class BlogTitle extends React.Component {
  render() {
    return (
      <div className="blog-title">
        <p>
          <Link to={this.props.slug}>{this.props.title}</Link>
          <small>{this.props.date}</small>
        </p>
      </div>
    );
  }
}

export default BlogTitle;
