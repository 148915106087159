import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import "./blog-post.scss";
import BlogTitle from "../components/blog-title";
import Helmet from "react-helmet";

export default ({ data }) => {
  const post = data.markdownRemark;
  return (
    <Layout writing={true} showHeader={true} showFooter={true}>
      <Helmet title={`${post.frontmatter.title} | Christopher Andersson`} />
      <article>
        <BlogTitle
          key={post.id}
          slug={post.fields.slug}
          title={post.frontmatter.title}
          date={post.frontmatter.date}
        />
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </article>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`;
